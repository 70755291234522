import { gql } from '@urql/core';

interface FavouriteAdDelete {
    favouriteAdDelete: {
        __typename: string;
        success?: boolean;
        error?: string;
    };
}

export const USER_FAVOURITE_DELETE_MUTATION = gql<FavouriteAdDelete>`
    mutation UserFavoriteDelete($adId: Int64!) {
        favouriteAdDelete: userFavoriteDel(adId: $adId) {
            __typename
            ... on FavoriteDel {
                Status
                Message
            }
            ... on InternalError {
                message
            }
        }
    }
`;

export const USER_FAVOURITE_DELETE_NEW_MUTATION = gql<FavouriteAdDelete>`
    mutation UserFavoriteDelete($advertID: Int64!) {
        favouriteAdDelete: favouriteAdvertsDelete(advertID: $advertID) {
            __typename
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
