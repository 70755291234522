import { CACHE_PREFIX } from '@domains/shared/consts/cachePrefix';
import { handleWebApiError } from '@domains/shared/helpers/handleWebApiError';
import { logError, logWarn } from '@domains/shared/helpers/logger';
import { useCallback, useEffect, useRef } from 'react';

interface GetValueOptions<T> {
    shouldParse?: T;
}
type GetValue<T> = (options?: GetValueOptions<T>) => T extends true ? unknown : string | null;
type SetValue = (value: unknown) => void;
type RemoveValue = () => void;

export const useLocalStorageCache = <T extends boolean>(key: string): [GetValue<T>, SetValue, RemoveValue] => {
    const storage = useRef<Storage | null>(null);

    useEffect(() => {
        try {
            storage.current = window.localStorage;
        } catch (error) {
            handleWebApiError(error, '[useLocalStorageCache]');
        }
    }, []);

    const getValue = useCallback<GetValue<T>>(
        (options = {}) => {
            const { shouldParse = true } = options;

            if (!storage.current) {
                logWarn('[useLocalStorageCache] get, storage is not accessible', { key });

                return;
            }

            try {
                const data = storage.current.getItem(`${CACHE_PREFIX}${key}`);

                if (data) {
                    return shouldParse ? JSON.parse(data) : data;
                }
            } catch (error) {
                logError('[useLocalStorageCache] get', { key, error });
            }

            return null;
        },
        [key],
    );

    const setValue = useCallback<SetValue>(
        (value) => {
            if (!storage.current) {
                logWarn('[useLocalStorageCache] set, storage is not accessible', { key });

                return;
            }

            try {
                storage.current.setItem(`${CACHE_PREFIX}${key}`, JSON.stringify(value));
            } catch (error) {
                logError('[useLocalStorageCache] set', { key, error });
            }
        },
        [key],
    );

    const removeValue = useCallback<RemoveValue>(() => {
        if (!storage.current) {
            logWarn('[useLocalStorageCache] remove, storage is not accessible', { key });

            return;
        }

        try {
            storage.current.removeItem(`${CACHE_PREFIX}${key}`);
        } catch (error) {
            logError('[useLocalStorageCache] remove', { key, error });
        }
    }, [key]);

    return [getValue, setValue, removeValue];
};
