import type { RoomsNumber } from '@type/search/filters/roomsNumber';

export const ROOM_NUMBERS_MAP: Record<RoomsNumber, string> = {
    /* eslint-disable @typescript-eslint/naming-convention -- RoomNumber entries are UpperCase*/
    ONE: '1',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
    SIX: '6',
    SEVEN: '7',
    EIGHT: '8',
    NINE: '9',
    TEN: '10',
    MORE: 'more',
    TEN_OR_MORE: 'more',
    SIX_OR_MORE: 'more',
    /* eslint-enable @typescript-eslint/naming-convention */
};

/**
 * On PT market instead of showing number of rooms a typology parameter is used. The typology parameter shows the number of bedrooms, so T1 means a 1-bedroom + living room (so 2 rooms in PL). T0 means a studio appartment.
 */
const ROOM_NUMBERS_TYPOLOGY_MAP: Record<RoomsNumber, string> = {
    /* eslint-disable @typescript-eslint/naming-convention -- RoomNumber entries are UpperCase*/
    ONE: '0',
    TWO: '1',
    THREE: '2',
    FOUR: '3',
    FIVE: '4',
    SIX: '5',
    SEVEN: '6',
    EIGHT: '7',
    NINE: '8',
    TEN: '9',
    MORE: 'more',
    TEN_OR_MORE: 'more',
    SIX_OR_MORE: 'more',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const getRoomNumberByStringValue = (stringNumberValue: RoomsNumber): string => {
    return ROOM_NUMBERS_MAP[stringNumberValue];
};

export const getRoomNumberByNumeralValue = (value: string): RoomsNumber | undefined => {
    const result = Object.entries(ROOM_NUMBERS_MAP).find((item) => item[1] === value);

    return result ? (result[0] as RoomsNumber) : undefined;
};

export const getRoomNumberTypologyByStringValue = (stringNumberValue: RoomsNumber): string => {
    return ROOM_NUMBERS_TYPOLOGY_MAP[stringNumberValue];
};
