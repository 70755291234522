import type { GenericGraphQLError } from '@lib/graphql/error';
import { gql } from '@urql/core';

/**
 * Please note, either it looks like, this query is not yet normalized in GQL error handling
 */
interface UserSavedAds {
    favouriteAds?:
        | GenericGraphQLError
        | {
              __typename: 'FavoriteAdsList';
              items: {
                  id: number;
              }[];
          };
    observedAds?:
        | GenericGraphQLError
        | {
              __typename: 'FavouriteAdvertsList';
              items: {
                  id: number;
              }[];
          };
}

interface UserSavedAdsVariables {
    isObservedAdsPageEnabled: boolean;
}

export const GET_USER_SAVED_ADS_QUERY = gql<UserSavedAds, UserSavedAdsVariables>`
    query GetUserSavedAdsQuery($isObservedAdsPageEnabled: Boolean!) {
        observedAds: favouriteAdvertsGetAll(withAdvertData: false) @include(if: $isObservedAdsPageEnabled) {
            __typename
            ... on FavouriteAdvertsList {
                items {
                    id: advertID
                }
            }
        }
        favouriteAds: userFavoriteAds @skip(if: $isObservedAdsPageEnabled) {
            __typename
            ... on FavoriteAdsList {
                items {
                    id
                }
            }
            ... on CannotGetFavoriteAds {
                message
            }
            ... on InternalError {
                message
            }
        }
    }
`;
