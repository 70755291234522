import { ObserveAdDescription } from '@domains/ad/components/ObserveAdDescription/ObserveAdDescription';
import { ObserveAdDescriptionHciamExperiment } from '@domains/ad/components/ObserveAdDescriptionHciamExperiment/ObserveAdDescriptionHciamExperiment';
import { OBSERVE_AD_PARAMETER } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import dynamic from 'next/dynamic';
import { useCallback, useState } from 'react';

const LazyRedirectToLoginModal = dynamic(
    () => import('@domains/shared/components/RedirectToLoginModal/RedirectToLoginModal'),
    { ssr: false },
);

const LazyObserveAdRegistrationModal = dynamic(
    () => import('@domains/ad/components/ObserveAdRegistrationModal/ObserveAdRegistrationModal'),
    { ssr: false },
);

type ModalVariant = 'RedirectToLoginModal' | 'RegistrationModal' | null;

interface ObserveAdModalProps {
    onClose(): void;
    extraTrackingData?: Record<string, unknown>;
    onSuccessfulLogin?(): void;
    afterLoginRedirectPath?: string;
    id?: number;
}

export const ObserveAdModal = ({
    onClose,
    extraTrackingData,
    onSuccessfulLogin,
    afterLoginRedirectPath,
    id,
}: ObserveAdModalProps): React.ReactElement | null => {
    const [t] = useTranslations();
    const [currentModal, setCurrentModal] = useState<ModalVariant>('RedirectToLoginModal');

    const openRegistrationModal = useCallback(() => {
        setCurrentModal('RegistrationModal');
    }, [setCurrentModal]);

    const redirectUrl = afterLoginRedirectPath || `${window.location.href.replace(/#.*/, '')}#${OBSERVE_AD_PARAMETER}`;

    const {
        featureFlags: { isHCIAMEnabled },
    } = useSiteSettings();

    switch (currentModal) {
        case 'RedirectToLoginModal': {
            return (
                <LazyRedirectToLoginModal
                    title={t('frontend.ad.observe-ad-modal.title')}
                    description={isHCIAMEnabled ? <ObserveAdDescriptionHciamExperiment /> : <ObserveAdDescription />}
                    isOpen
                    onClose={onClose}
                    afterLoginRedirectPath={redirectUrl}
                    trackingData={extraTrackingData}
                    showRegistrationModal={openRegistrationModal}
                />
            );
        }
        case 'RegistrationModal': {
            return (
                <LazyObserveAdRegistrationModal
                    isOpen
                    closeModal={onClose}
                    trackingData={extraTrackingData}
                    onSuccessfulLogin={onSuccessfulLogin}
                    afterLoginRedirectPath={redirectUrl}
                    id={id}
                />
            );
        }
        default: {
            return null;
        }
    }
};

export default ObserveAdModal;
