import { toast } from '@domains/shared/components/Toast/toast';
import { AD_SAVED_EVENT_NAME } from '@domains/shared/consts/adSavedEventName';
import { getParamsForSaveAdMutation } from '@domains/shared/contexts/SavedAdsContext/helpers/getParamsForSaveAdMutation';
import { logError } from '@domains/shared/helpers/logger';
import { useShouldPauseQueryForLogoutUser } from '@domains/shared/hooks/useShouldPauseQueryForLogoutUser/useShouldPauseQueryForLogoutUser';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { dispatchPlatformEvent } from '@lib/events';
import type { UseAssertGraphQlPropsGeneric } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { normalizeForbiddenAccessQuery } from '@lib/graphql/normalizeForbiddenAccessQuery';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC, JSX, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CombinedError } from 'urql';
import { useClient, useQuery } from 'urql';

import {
    USER_FAVOURITE_ADD_MUTATION,
    USER_FAVOURITE_ADD_NEW_MUTATION,
} from './graphql/mutations/UserFavoriteAddMutation';
import {
    USER_FAVOURITE_DELETE_MUTATION,
    USER_FAVOURITE_DELETE_NEW_MUTATION,
} from './graphql/mutations/UserFavoriteDelMutation';
import { GET_USER_SAVED_ADS_QUERY } from './graphql/queries/UserSavedAdsQuery';
import { SAVED_ADS_TYPE_NAMES } from './savedAdsTypeName';

type PartialUseAssertGraphQlPropsGeneric = Pick<
    UseAssertGraphQlPropsGeneric,
    'fetching' | 'graphqlError' | 'logErrorPrefix' | 'onTypeMismatch' | 'operation' | 'onUserError' | 'pause'
>;

interface ToggleSubscription {
    id: number;
    trackingData?: Record<string, unknown>;
    toastLabelWithUndoButton?: JSX.Element;
    savedAdNote?: string;
    adCreationTimestamp?: number;
}

interface SavedAdsContextProps {
    adsIdInUpdateState: Set<number>;
    checkIfAdIsSaved: (id: number) => boolean;
    isFetchingSavedAds: boolean;
    toggleSubscription: (params: ToggleSubscription) => Promise<boolean | undefined>;
}

export const SavedAdsContext = createContext<SavedAdsContextProps>({
    adsIdInUpdateState: new Set(),
    checkIfAdIsSaved: () => false,
    isFetchingSavedAds: false,
    toggleSubscription: () => Promise.resolve(undefined),
});

const EXPECTED_OBSERVED_ADS_TYPENAME = ['FavouriteAdvertsList'] as const;
const EXPECTED_FAVOURITE_ADS_TYPENAME = ['FavoriteAdsList'] as const;
const USER_SAVED_ADS_CONTEXT = { additionalTypenames: SAVED_ADS_TYPE_NAMES };
const LIMIT_REACHED_TYPENAME = 'FavoriteAdsLimitReached';
const EMPTY_OBSERVED_ADS_LIST: number[] = [];

export const SavedAdsContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const {
        featureFlags: { isObservedAdsPageEnabled },
    } = useSiteSettings();

    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const operationsInProgressReg = useRef(0);
    const [adsIdInUpdateState, setAdsIdInUpdateState] = useState<Set<number>>(new Set([]));
    const shouldPauseQuery = useShouldPauseQueryForLogoutUser();
    const [{ data, error: graphqlError, fetching, operation, stale }] = useQuery({
        query: GET_USER_SAVED_ADS_QUERY,
        pause: shouldPauseQuery,
        context: USER_SAVED_ADS_CONTEXT,
        variables: {
            isObservedAdsPageEnabled: !!isObservedAdsPageEnabled,
        },
    });

    const urqlClient = useClient();
    const commonUserDataConfig: PartialUseAssertGraphQlPropsGeneric = {
        fetching,
        graphqlError,
        logErrorPrefix: '[SavedAdsContext]',
        onTypeMismatch: 'DO_NOTHING', // We have to do nothing as instead of typename, we receive here an error 'Forbidden Access'
        operation,
        pause: shouldPauseQuery,
        onUserError: 'CHECK_AND_DO_NOTHING',
    };
    const observedAds = useAssertGraphqlResponse({
        ...commonUserDataConfig,
        data: normalizeForbiddenAccessQuery(data?.observedAds, graphqlError),
        expectedTypenames: EXPECTED_OBSERVED_ADS_TYPENAME,
        pause: shouldPauseQuery,
        onTypeMismatch: isObservedAdsPageEnabled ? 'LOG_ERROR' : 'DO_NOTHING',
    });

    const favouriteAds = useAssertGraphqlResponse({
        ...commonUserDataConfig,
        data: normalizeForbiddenAccessQuery(data?.favouriteAds, graphqlError),
        expectedTypenames: EXPECTED_FAVOURITE_ADS_TYPENAME,
        pause: shouldPauseQuery,
        onTypeMismatch: isObservedAdsPageEnabled ? 'DO_NOTHING' : 'LOG_ERROR',
    });

    // Extract to the separate step to avoid re-creating an array when `fetching` state changes
    const savedAds = useMemo(() => {
        const items = observedAds?.items || favouriteAds?.items;

        if (!items) {
            return EMPTY_OBSERVED_ADS_LIST;
        }

        return items.map((item) => item.id);
    }, [favouriteAds, observedAds]);

    useEffect(() => {
        // Reset ads in the update state when we get the final response with observed ads
        // Note:
        // * stale is a status when the query data is outdated (e.g. a mutation with related typenames was executed)
        // * operationsInProgressReg.current - delay a reset until we have a stable state without expected upcoming changes
        if (stale || operationsInProgressReg.current > 0) {
            return;
        }
        setAdsIdInUpdateState((previousSet) => (previousSet.size > 0 ? new Set() : previousSet));
    }, [savedAds, stale]);

    const toggleSubscription = useCallback<SavedAdsContextProps['toggleSubscription']>(
        async ({ id, trackingData, toastLabelWithUndoButton, savedAdNote = '', adCreationTimestamp }) => {
            const handleGenericError = (
                errors: CombinedError | null,
                errorMessage = 'frontend.toast.label.error',
            ): void => {
                setAdsIdInUpdateState((previousSet) => {
                    previousSet.delete(id);

                    return new Set(previousSet);
                });

                toast.error(t(errorMessage));
                logError('[SavedAdsContext] toggleSubscription failed', { errors });
            };

            operationsInProgressReg.current += 1;
            setAdsIdInUpdateState((previousSet) => new Set(previousSet.add(id)));

            // Providing the timestamp means that we're undoing saved ad removal
            if (savedAds.includes(id) && !adCreationTimestamp) {
                const params = isObservedAdsPageEnabled ? { advertID: id } : { adId: id };

                const { data, error } = await urqlClient
                    .mutation(
                        isObservedAdsPageEnabled ? USER_FAVOURITE_DELETE_NEW_MUTATION : USER_FAVOURITE_DELETE_MUTATION,
                        params,
                        { additionalTypenames: SAVED_ADS_TYPE_NAMES },
                    )
                    .toPromise();
                operationsInProgressReg.current -= 1;
                if (!['FavoriteDel', 'OperationSearchSuccess'].includes(data?.favouriteAdDelete?.__typename || '')) {
                    handleGenericError(error || null);

                    return;
                }
                trackEvent('favourite_ad_deleted', trackingData);

                if (toastLabelWithUndoButton) {
                    toast.success(toastLabelWithUndoButton, { shouldHideCloseButton: true });
                }

                return false;
            }

            const { data, error } = await urqlClient
                .mutation(
                    isObservedAdsPageEnabled ? USER_FAVOURITE_ADD_NEW_MUTATION : USER_FAVOURITE_ADD_MUTATION,
                    getParamsForSaveAdMutation({
                        id,
                        note: savedAdNote,
                        createdAt: adCreationTimestamp,
                        isObservedAdsPageEnabled,
                    }),
                    { additionalTypenames: SAVED_ADS_TYPE_NAMES },
                )
                .toPromise();
            operationsInProgressReg.current -= 1;
            const isLimitReached = data?.favouriteAdAdd?.__typename === LIMIT_REACHED_TYPENAME;
            if (isLimitReached) {
                handleGenericError(null, 'frontend.subscribe.toast.limit-reached');

                return;
            }
            if (!['StatusResponse', 'OperationSearchSuccess'].includes(data?.favouriteAdAdd?.__typename || '')) {
                handleGenericError(error || null);

                return;
            }

            trackEvent('favourite_ad_click_success', trackingData);

            dispatchPlatformEvent(AD_SAVED_EVENT_NAME);

            return true;
        },
        [savedAds, isObservedAdsPageEnabled, urqlClient, trackEvent, t],
    );

    const value = useMemo(
        () => ({
            checkIfAdIsSaved: (id: number): boolean => savedAds.includes(id),
            adsIdInUpdateState,
            isFetchingSavedAds: fetching,
            toggleSubscription,
        }),
        [adsIdInUpdateState, fetching, savedAds, toggleSubscription],
    );

    return <SavedAdsContext.Provider value={value}>{children}</SavedAdsContext.Provider>;
};
