import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

interface Props {
    descriptionTextType?: 'registration' | 'default';
}

const DESCRIPTION_KEYS = {
    registration: 'frontend.ad.save-ad-registration-modal-description.paragraphs',
    default: 'frontend.ad.observe-ad-description.paragraphs',
} as const;

export const ObserveAdDescription = ({ descriptionTextType = 'default' }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <>
            {replacePlaceholders(t(DESCRIPTION_KEYS[descriptionTextType]), (text) => (
                <p>{text}</p>
            ))}
        </>
    );
};
