type ParamsForSaveAdMutation =
    | {
          advertID: number;
          note: string;
      }
    | {
          advertID: number;
          note: string;
          createdAt: number;
      }
    | {
          adId: number;
      };

export const getParamsForSaveAdMutation = ({
    id,
    note,
    createdAt,
    isObservedAdsPageEnabled,
}: {
    id: number;
    note: string;
    createdAt: number | undefined;
    isObservedAdsPageEnabled: boolean | null;
}): ParamsForSaveAdMutation => {
    if (isObservedAdsPageEnabled && !createdAt) {
        return { advertID: id, note: note };
    }

    if (isObservedAdsPageEnabled && createdAt) {
        return { advertID: id, note: note, createdAt };
    }

    return { adId: id };
};
