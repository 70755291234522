// FIXME: Convert into widget/favorites
import { ObserveAdModal } from '@domains/ad/components/ObserveAdModal/ObserveAdModal';
import { ToastLabelWithUndoButton } from '@domains/shared/components/ToastLabelWithUndoButton/ToastLabelWithUndoButton';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { OBSERVE_AD_DATA_CACHE } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons/faHeart';
import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useTracking } from '@lib/tracking/useTracking';
import { Button as NexusButton } from '@nexus/lib-react/dist/core/Button';
import HeartDefault from '@nexus/lib-react/dist/core/Icon/icons/default/HeartDefault';
import HeartFilled from '@nexus/lib-react/dist/core/Icon/icons/default/HeartFilled';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { FC, MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import {
    Button,
    ButtonContent,
    FetchingIconWrapper,
    LoadingIcon,
    NexusHeartDefaultLoadingIcon,
    StyledIcon,
} from './AddToFavorites.theme';
import { ICON_LENGTH } from './consts/dimensions';

export const HANDLE_SUBSCRIPTION_PLATFORM_EVENT = 'addToSavedAdsIconClick';

export const ADD_TO_FAVORITES_BUTTON_VARIANT = {
    primary: 'primary',
    secondary: 'secondary',
    ghost: 'ghost',
} as const;

export type AddToFavoritesPlacement = 'listing' | 'ad-page';

interface Props {
    id: number;
    isRedirectToLoginModalOpen?: boolean;
    setIsRedirectToLoginModalOpen?: (value: boolean) => void;
    variant?: ObjectValues<typeof ADD_TO_FAVORITES_BUTTON_VARIANT>;
    className?: string;
    trackingData?: Record<string, unknown>;
    placement?: AddToFavoritesPlacement;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    onStateChange?: (isFavourite: boolean, id: number) => void;
    onClick?: () => void;
    loadingIconWrapper?: FC;
    shouldUseNexusTheme?: boolean;
    shouldShowUndoButtonInToast?: boolean;
    savedAdNote?: string;
    showAsNexusButton?: boolean;
    label?: string;
    nexusButtonSize?: 'large' | 'small';
    nexusButtonVariant?: 'primary' | 'secondary' | 'flat';
}

const DEFAULT_TRACKING_DATA = {};

export const AddToFavorites: FC<Props> = ({
    id,
    isRedirectToLoginModalOpen,
    setIsRedirectToLoginModalOpen,
    variant = ADD_TO_FAVORITES_BUTTON_VARIANT.primary,
    className,
    trackingData = DEFAULT_TRACKING_DATA,
    placement,
    setSimilarAdsPromptId,
    onStateChange,
    onClick,
    loadingIconWrapper: LoadingIconWrapper = FetchingIconWrapper,
    shouldUseNexusTheme = false,
    shouldShowUndoButtonInToast,
    showAsNexusButton,
    label,
    nexusButtonSize = 'large',
    nexusButtonVariant = 'primary',
}) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isAwaitingUserData, isUserLoggedIn } = useLazyUser();
    const { adsIdInUpdateState, checkIfAdIsSaved, isFetchingSavedAds, toggleSubscription } =
        useContext(SavedAdsContext);
    const isFavorite = checkIfAdIsSaved(id);

    const [, setObserveAdData] = useLocalStorageCache(OBSERVE_AD_DATA_CACHE);

    const trackingParams = useMemo(() => ({ ...trackingData, ad_id: id }), [trackingData, id]);

    const handleSubscribeButtonClick = useCallback((): void => {
        onClick?.();
        if (!isFavorite) {
            trackEvent('favourite_ad_click', trackingParams);
        }

        if (!isUserLoggedIn) {
            setObserveAdData({ id, trackingData: trackingParams });
            setIsRedirectToLoginModalOpen?.(true);

            return;
        }

        const toastLabelWithUndoButton = shouldShowUndoButtonInToast ? (
            <ToastLabelWithUndoButton adId={id} />
        ) : undefined;

        toggleSubscription({
            id,
            trackingData: trackingParams,
            toastLabelWithUndoButton,
        }).then((isFavourite) => {
            if (isFavourite === undefined) {
                return;
            }
            if (isFavourite) {
                // Expand the similar ads prompt for this particular ad
                setSimilarAdsPromptId?.(id);
            }
            onStateChange?.(isFavourite, id);
        });
    }, [
        isUserLoggedIn,
        setIsRedirectToLoginModalOpen,
        isFavorite,
        trackEvent,
        trackingParams,
        id,
        setObserveAdData,
        setSimilarAdsPromptId,
        toggleSubscription,
        onStateChange,
        onClick,
        shouldShowUndoButtonInToast,
    ]);

    useEffect(() => {
        listenForPlatformEvent(HANDLE_SUBSCRIPTION_PLATFORM_EVENT, handleSubscribeButtonClick);

        return (): void => {
            unmountPlatformEvent(HANDLE_SUBSCRIPTION_PLATFORM_EVENT, handleSubscribeButtonClick);
        };
    }, [handleSubscribeButtonClick]);

    const handleWrapperClickEvent = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.stopPropagation(); //prevent sending adPage click tracking event on opening modal
        event.preventDefault(); //prevent redirect to adPage
    }, []);

    const isInProgress = adsIdInUpdateState.has(id);

    const buttonCy =
        placement === 'listing' ? 'listing-subscribe-button.subscribe' : 'ad-page-subscribe-button.subscribe';

    const icon = isFavorite || isInProgress ? faHeartSolid : faHeartRegular;
    const NexuxIcon = isFavorite || isInProgress ? HeartFilled : HeartDefault;

    if (isFetchingSavedAds || isAwaitingUserData) {
        return (
            <>
                {shouldUseNexusTheme ? (
                    <LoadingIconWrapper>
                        <NexusHeartDefaultLoadingIcon width={ICON_LENGTH} height={ICON_LENGTH} />
                    </LoadingIconWrapper>
                ) : (
                    <LoadingIconWrapper>
                        <LoadingIcon size="lg" icon={faHeartRegular} width={ICON_LENGTH} height={ICON_LENGTH} />
                    </LoadingIconWrapper>
                )}
            </>
        );
    }

    const commonProps = {
        onClick: handleSubscribeButtonClick,
        disabled: isInProgress,
        className,
        isFavorite,
        ['data-cy']: buttonCy,
        ['data-cy-subscribed']: isFavorite,
        ['data-testid']: 'add-to-favorites-button',
        ['aria-label']: t('frontend.subscribe.add-to-favorites'),
    };

    const optionalLabel = label ? <span>{label}</span> : null;
    const nexusIcon = <NexuxIcon color="inherit" width={ICON_LENGTH} height={ICON_LENGTH} data-testid="nexus-icon" />;
    const oldIcon = (
        <StyledIcon size="lg" icon={icon} width={ICON_LENGTH} height={ICON_LENGTH} data-testid="old-icon" />
    );

    return (
        <div onClick={handleWrapperClickEvent}>
            {showAsNexusButton ? (
                <NexusButton {...commonProps} variant={nexusButtonVariant} size={nexusButtonSize}>
                    <ButtonContent data-testid="add-to-favorites-nexus-button">
                        {nexusIcon}
                        {optionalLabel}
                    </ButtonContent>
                </NexusButton>
            ) : (
                <Button {...commonProps} variant={variant} type="button" shouldUseNexusTheme={shouldUseNexusTheme}>
                    <ButtonContent data-testid={'add-to-favorites-old-button'}>
                        {shouldUseNexusTheme ? nexusIcon : oldIcon}
                        {optionalLabel}
                    </ButtonContent>
                </Button>
            )}

            {isRedirectToLoginModalOpen ? (
                <ObserveAdModal
                    onClose={(): void => setIsRedirectToLoginModalOpen?.(false)}
                    extraTrackingData={trackingParams}
                    id={id}
                />
            ) : null}
        </div>
    );
};
