import { SavedAdsDataContext } from '@domains/clipboard/components/SavedAds/SavedAdsProviders/contexts/SavedAdsDataContext';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useContext } from 'react';
import toast from 'react-hot-toast';

import { Button, ButtonWrapper, Container } from './ToastLabelWithUndoButton.theme';

interface Props {
    adId: number;
}

export const ToastLabelWithUndoButton = ({ adId }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { restoreAd, savedAds } = useContext(SavedAdsDataContext);
    const { toggleSubscription } = useContext(SavedAdsContext);
    const { trackEvent } = useTracking();

    const ad = savedAds?.find((ad) => ad.advertID === adId);
    const note = ad?.note;
    const adCreationTimestamp = ad?.createdAt;

    const handleClick = (): void => {
        toast.remove();
        trackEvent('saved_ad_delete_undo');
        toggleSubscription({
            id: adId,
            trackingData: {
                touch_point_button: 'save_ad_undo',
            },
            savedAdNote: note,
            adCreationTimestamp,
        }).then(() => {
            restoreAd(`${adId}`);
        });
    };

    return (
        <Container>
            <span>{t('frontend.subscribe.toast.success-remove')}</span>
            <ButtonWrapper>
                <Button onClick={handleClick} data-cy="toast.undo-button">
                    {t('frontend.shared.toast.undo')}
                </Button>
            </ButtonWrapper>
        </Container>
    );
};
