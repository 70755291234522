import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Button = styled.span`
    margin: 0 0 0 24px;
    color: ${({ theme }): string => theme.deprecated.domains.shared.toast.label.color};
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    cursor: pointer;

    @media (min-width: ${BREAKPOINT.xs}) {
        margin: 0 0 0 32px;
    }
`;
