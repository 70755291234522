import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import { useCallback, useContext, useEffect } from 'react';

export const OBSERVE_AD_PARAMETER = 'observeAd';
export const OBSERVE_AD_DATA_CACHE = 'OBSERVE_AD_DATA';

interface ObserveAdDataCache {
    id: number;
    trackingData: Record<string, unknown>;
}
const checkIsObserveAdDataCache = (data: unknown): data is ObserveAdDataCache =>
    typeof data === 'object' &&
    data !== null &&
    typeof (data as ObserveAdDataCache).id === 'number' &&
    typeof (data as ObserveAdDataCache).trackingData === 'object';

/**
 * This hook listens to hash change.
 * If the hash is `observeAd` and the data about the observed ad is saved in localStorage,
 * the subscription handler to observe this ad will be called
 */
export const useFavoritesSubscriptionHash = (setSimilarAdsPromptId?: SetSimilarAdsPromptId): void => {
    const [getObserveAdDataFromLocalStorage, , removeObserveAdDataFromLocalStorage] =
        useLocalStorageCache(OBSERVE_AD_DATA_CACHE);

    const { toggleSubscription } = useContext(SavedAdsContext);

    const handleHashChangeEvent = useCallback((): void => {
        if (typeof window !== 'undefined' && window.location.hash.includes(OBSERVE_AD_PARAMETER)) {
            const cacheData = getObserveAdDataFromLocalStorage();
            const observeAdData = checkIsObserveAdDataCache(cacheData) ? cacheData : undefined;
            removeObserveAdDataFromLocalStorage();

            if (observeAdData?.id) {
                toggleSubscription({
                    id: observeAdData.id,
                    trackingData: observeAdData.trackingData,
                }).then((isFavourite) => {
                    if (isFavourite === true) {
                        // Expand the similar ads prompt for this particular ad
                        setSimilarAdsPromptId?.(observeAdData?.id);
                    }
                });
            }
            window.removeEventListener('hashchange', handleHashChangeEvent, false);
            window.location.hash = '';
        }
    }, [
        getObserveAdDataFromLocalStorage,
        removeObserveAdDataFromLocalStorage,
        setSimilarAdsPromptId,
        toggleSubscription,
    ]);

    useEffect(() => {
        /* this call is needed to subscribe after page gets reloaded during email login */
        handleHashChangeEvent();

        window.addEventListener('hashchange', handleHashChangeEvent, false);

        /* listener should be removed when user leaves the page */
        return (): void => window.removeEventListener('hashchange', handleHashChangeEvent, false);
    }, [handleHashChangeEvent]);
};
