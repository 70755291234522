import type { ADD_TO_FAVORITES_BUTTON_VARIANT } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';
import { theme as nexusTheme } from '@lib/styles/themes/nexus';
import type { Theme } from '@lib/styles/themes/type';
import HeartDefault from '@nexus/lib-react/dist/core/Icon/icons/default/HeartDefault';

interface ButtonProps {
    variant: ObjectValues<typeof ADD_TO_FAVORITES_BUTTON_VARIANT>;
    isFavorite: boolean;
    shouldUseNexusTheme?: boolean;
}

const getButtonColor = ({
    theme,
    variant,
    isFavorite,
    shouldUseNexusTheme,
}: ButtonProps & { theme: Theme }): string => {
    if (shouldUseNexusTheme) {
        return isFavorite ? nexusTheme.colors['icon-global-highlight'] : nexusTheme.colors['icon-brand-primary'];
    }

    return isFavorite
        ? theme.deprecated.domains.search.listingItem.base.actionIcon[variant].fill
        : theme.deprecated.domains.search.listingItem.base.actionIcon[variant].borderColor;
};

export const Button = styled.button<ButtonProps>`
    display: flex;
    grid-area: icon;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    outline: 0;
    background: none;
    color: ${getButtonColor};
    font-size: ${SIZE.p1};
    cursor: pointer;

    &:disabled {
        color: ${nexusTheme.colors['background-global-primary']};
        cursor: not-allowed;
    }
`;

export const FetchingIconWrapper = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${({ theme }): string =>
        theme.deprecated.domains.search.listingItem.base.actionIcon.secondary.backgroundColor};
    font-size: ${SIZE.p1};
    cursor: pointer;

    @media (min-width: ${BREAKPOINT.md}) {
        display: flex;
        grid-area: icon;
        width: 24px;
        height: 24px;
        border-radius: 0;
        background: none;
    }
`;

export const StyledIcon = styled(Icon)`
    transition: opacity 0.15s ease-in-out;

    @media (min-width: ${BREAKPOINT.md}) {
        :hover {
            opacity: 1;
        }
    }
`;

export const LoadingIcon = styled(StyledIcon)`
    opacity: 0.4;
    color: ${({ theme }): string => theme.deprecated.domains.search.listingItem.base.actionIcon.secondary.borderColor};
`;

export const NexusHeartDefaultLoadingIcon = styled(HeartDefault)<{ isFavorite?: boolean }>`
    transition: opacity 0.15s ease-in-out;
    opacity: 0.4;
    color: ${nexusTheme.colors['icon-brand-primary']};

    @media (min-width: ${BREAKPOINT.md}) {
        :hover {
            opacity: 1;
        }
    }
`;

export const ButtonContent = styled.span`
    display: flex;
    gap: ${nexusTheme.space['8']};
    align-items: center;
    justify-content: center;
`;
