import { gql } from '@urql/core';

interface FavouriteAdAdd {
    favouriteAdAdd: {
        __typename: string;
        success?: boolean;
        error?: string;
    };
}

export const USER_FAVOURITE_ADD_MUTATION = gql<FavouriteAdAdd>`
    mutation UserFavoriteAdd($adId: Int64!) {
        favouriteAdAdd: userFavoriteAdd(adId: $adId) {
            __typename
            ... on StatusResponse {
                Status
                Message
            }
            ... on InternalError {
                message
            }
        }
    }
`;

export const USER_FAVOURITE_ADD_NEW_MUTATION = gql<FavouriteAdAdd>`
    mutation UserFavouriteAdd($advertID: Int64!, $note: String!, $createdAt: Int64) {
        favouriteAdAdd: favouriteAdvertsCreate(advertID: $advertID, note: $note, createdAt: $createdAt) {
            __typename
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
