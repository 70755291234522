import type { SavedAd } from '@domains/clipboard/types/savedAds';
import type { JSX, PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

interface SavedAdsDataContextProps {
    savedAds?: SavedAd[];
    removeAd: (id: number) => void;
    restoreAd: (id: string) => void;
    updateNote: (id: string, note: string) => void;
    totalItems: number;
}

export const SavedAdsDataContext = createContext<SavedAdsDataContextProps>({
    savedAds: [],
    removeAd: () => undefined,
    restoreAd: () => undefined,
    updateNote: () => undefined,
    totalItems: 0,
});

interface SavedAdsDataContextProviderProps {
    savedAds?: SavedAd[];
    totalItems: number;
}

export const SavedAdsDataContextProvider = ({
    children,
    savedAds,
    totalItems,
}: PropsWithChildren<SavedAdsDataContextProviderProps>): JSX.Element => {
    const [ads, setAds] = useState<SavedAd[] | undefined>(savedAds);
    const [total, setTotal] = useState(totalItems);

    const removeAd = useCallback((id: number): void => {
        setAds((previousState) =>
            previousState?.map((ad) => (ad.advertID === id ? { ...ad, isRemovedFromPage: true } : ad)),
        );
        setTotal((state) => state - 1);
    }, []);

    const restoreAd = useCallback((id: string): void => {
        const numberId = Number(id);

        setAds((previousState) =>
            previousState?.map((ad) => (ad.advertID === numberId ? { ...ad, isRemovedFromPage: false } : ad)),
        );
        setTotal((state) => state + 1);
    }, []);

    const updateNote = useCallback((id: string, note: string): void => {
        setAds((previousState) =>
            previousState?.map((ad) => {
                if (ad.advertID === Number(id)) {
                    return { ...ad, note };
                }

                return ad;
            }),
        );
    }, []);

    const contextValue = useMemo(
        () => ({
            savedAds: ads,
            removeAd,
            restoreAd,
            updateNote,
            totalItems: total,
        }),
        [ads, removeAd, restoreAd, updateNote, total],
    );

    return <SavedAdsDataContext.Provider value={contextValue}>{children}</SavedAdsDataContext.Provider>;
};
