import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTracking } from '@lib/tracking/useTracking';
import type { RefObject } from 'react';
import { useRef } from 'react';

export const useAdvertImpressionsTracking = (
    containerRef: RefObject<HTMLElement>,
    trackingSet: Record<string, unknown>,
): void => {
    const { trackEvent } = useTracking();
    const isImpressionTrackedRef = useRef(false);

    useIntersection(containerRef, (_, observer) => {
        const { current } = containerRef;

        // prevent redundant calls caused by component re-rendering
        if (!isImpressionTrackedRef.current) {
            // spread in order to avoid cryptic type error
            trackEvent('ad_impressions', trackingSet);
            isImpressionTrackedRef.current = true;
        }

        if (current && observer) {
            // once trackEvent is called, there is no need for this observer
            observer.unobserve(current);
            observer.disconnect();
        }
    });
};
