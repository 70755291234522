import { PLATFORM_EVENT_PREFIX } from './platformEventPrefix';

export const dispatchPlatformEvent = (eventName: string, payload?: Record<string, unknown>): void => {
    if (typeof window === 'undefined') {
        return;
    }
    const event = new CustomEvent(`${PLATFORM_EVENT_PREFIX}_${eventName}`, {
        detail: {
            ...payload,
        },
    });

    window.dispatchEvent(event);
};
