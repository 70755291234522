export const SPECIAL_OFFER_VARIANT = {
    priceDiscount: 'PriceDiscount',
    attractivePaymentSchedule: 'AttractivePaymentSchedule',
    freeParking: 'FreeParking',
    preSale: 'PreSale',
    lastMinute: 'LastMinute',
} as const;

export type SpecialOfferValue = ObjectValues<typeof SPECIAL_OFFER_VARIANT>;

export interface SpecialOffer {
    details: {
        startDate: string | null;
        endDate: string | null;
        appliesToAllUnits?: boolean;
    };
    discountValue?: number;
    minPriceLastDays?: number;
    __typename: SpecialOfferValue;
}

export const SPECIAL_OFFER_VALUE = {
    priceDiscount: 'price_discount',
    freeParking: 'free_parking',
    attractivePaymentSchedule: 'attractive_payment_schedule',
    preSale: 'pre_sale_offer',
    lastMinute: 'last_minute_offer',
} as const;

export type SpecialOfferOptionValue = ObjectValues<typeof SPECIAL_OFFER_VALUE>;

/* eslint-disable @typescript-eslint/naming-convention -- describes data returned from BE */
export const SPECIAL_OFFER_TYPE = {
    PriceDiscount: 'price_discount',
    FreeParking: 'free_parking',
    AttractivePaymentSchedule: 'attractive_payment_schedule',
    PreSale: 'pre_sale_offer',
    LastMinute: 'last_minute_offer',
} as const;
/* eslint-enable @typescript-eslint/naming-convention */
