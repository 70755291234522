import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

import { Description, Wrapper } from './ObserveAdDescriptionHciamExperiment.theme';

const { staticAssetsPrefix } = getPublicEnvConfig();

export const ObserveAdDescriptionHciamExperiment = (): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Wrapper>
            <img src={`${staticAssetsPrefix}/images/addToFavourites/heart.svg`} width="96" height="96" alt="" />
            <H4>{t('frontend.ad.observe-ad-description-hciam-experiment.title')}</H4>
            <Description>
                {replacePlaceholders(t('frontend.ad.observe-ad-description-hciam-experiment.description'), () => (
                    <br />
                ))}
            </Description>
        </Wrapper>
    );
};
