import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.space[16]};
`;

export const Description = styled(P3)`
    text-align: center;
`;
