import { logError, logWarn } from '@domains/shared/helpers/logger';
import type { Locale } from '@lib/i18n/types/locale';
import type { PluralTranslation } from '@lib/i18n/types/plural';

interface Parameters {
    lang: Locale;
    value: number;
    translation: PluralTranslation;
}

/**
 * Please read the comment for PluralTranslation interface for more details!
 */
export const getPluralTranslationVariant = ({ lang, value, translation }: Parameters): string => {
    try {
        const pluralRules = new Intl.PluralRules(lang);
        const variant = pluralRules.select(value);

        return translation[variant];
    } catch (error) {
        if (error instanceof RangeError) {
            logError(`[getPluralTranslationVariant] PluralRules not available for locale: ${lang}`);
        } else if (error instanceof TypeError) {
            logWarn('[getPluralTranslationVariant] PluralRules API is not supported');
        } else {
            logError('[getPluralTranslationVariant] Unexpected error', { error });
        }
    }

    // Fallback variants
    return translation.other;
};
